/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-for-in-array */
import { ConfigName } from 'config/utils/config'
import { getEnv } from 'utils/env'
import { getStorage } from 'utils/storage'
import { getFormattedPurpose } from './getFormattedPurpose'

export interface UrlParams {
  formConfig?: ConfigName // Optional: Which Taurine config to load for the current session.
  advertisementId?: string
  configuration?: string
  amount?: number
  term?: number
  purpose?: string
  productId?: number
  anchor?: string
  resume?: string
  subId?: string
  refId?: string
  externalId?: string
  backUrl?: string
  // this is for v1;
  // v2 might get this automatically from the used advertisementId
  clientUuid?: string
  palette?: string
  entryPoint?: string
  initialPayment?: number
  vehiclePrice?: number
  // send only in affiliate context
  context?: string
  directToOffers?: string
  utm_source?: string
  affiliateLinkout?: string
  embedded?: string
}

declare global {
  interface Window {
    OnetrustActiveGroups: string
  }
}

export const urlPathMap: Omit<
  Record<keyof UrlParams, string>,
  | 'formConfig'
  | 'anchor'
  | 'resume'
  | 'backlink'
  | 'backUrl'
  | 'palette'
  | 'entryPoint'
  | 'asTestId'
  | 'context'
  | 'directToOffers'
  | 'utm_source'
  | 'affiliateLinkout'
  | 'embedded'
> = {
  amount: 'common.amount',
  purpose: 'common.purpose',
  term: 'common.term',
  advertisementId: 'system.advertisementId',
  configuration: 'system.configurationId',
  refId: 'system.refId',
  subId: 'system.subId',
  externalId: 'system.externalId',
  productId: 'common.productId',
  clientUuid: 'system.clientUuid',
  initialPayment: 'common.vehicle.initialPayment',
  vehiclePrice: 'common.vehicle.price'
}

export const castToNumber = (param: string) => parseInt(param, 10)

const ffgUrlParams: Record<
  keyof UrlParams,
  null | ((value: string) => unknown)
> = {
  term: castToNumber,
  formConfig: null,
  advertisementId: null,
  configuration: null,
  purpose: (purpose) => getFormattedPurpose(purpose),
  amount: castToNumber,
  productId: castToNumber,
  anchor: null,
  resume: null,
  subId: null,
  refId: null,
  externalId: castToNumber,
  backUrl: null,
  clientUuid: null,
  palette: null,
  entryPoint: null,
  initialPayment: castToNumber,
  vehiclePrice: castToNumber,
  context: null,
  affiliateLinkout: null,
  embedded: null,
  directToOffers: null,
  utm_source: null
}

export const getFfgUrlParams = (search: string): UrlParams => {
  const params = new URLSearchParams(search)

  return Object.entries(ffgUrlParams).reduce(
    (previous, [paramName, castFunc]) => {
      const paramValue = params.get(paramName)

      if (!paramValue) {
        return previous
      }

      return {
        ...previous,
        [paramName]: castFunc ? castFunc(paramValue) : paramValue
      }
    },
    {}
  )
}

export const getClientUuid = () => {
  return getEnv('REACT_APP_TENANT_UUID', true)
}

export const isTenantSmava = () => {
  const domain = getEnv('REACT_APP_DOMAIN', true)
  return domain.indexOf('smava') > -1
}

export const getTrackingUrl = () =>
  `${getEnv('REACT_APP_WEBSITE_URL')}${window.location.pathname.slice(1)}`

export const getTrackingPath = () =>
  `/kreditantrag/${window.location.pathname.slice(1)}`

export const getDefaultAffiliateId = () =>
  parseInt(getEnv('REACT_APP_TAURINE_AFFILIATE_ID') || '', 10)
