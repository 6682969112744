import { NavigateFunction } from 'react-router'
import FFGFormNavigation from './FFGFormNavigation'
import SmavaFormNavigation from 'components/Smava/FormNavigation'

interface Props {
  isSmava?: boolean
  config?: string | undefined
  onBack?: () => void
  onNext: () => void
  navigate: NavigateFunction
  isOfferLabel?: boolean
}

const FormNavigationSwitcher = (props: Props) => {
  if (props.isSmava) {
    return (
      <SmavaFormNavigation
        onNext={props.onNext}
        onBack={props.onBack}
        legalContact="info@smava.de"
        navigate={props.navigate}
        isOfferLabel={props.isOfferLabel}
      />
    )
  }

  return (
    <FFGFormNavigation
      onNext={props.onNext}
      onBack={props.onBack}
      legalContact="kredite@finanzcheck.de"
      navigate={props.navigate}
    />
  )
}
export { FormNavigationSwitcher }
