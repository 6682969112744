import { memo } from 'react'
import MultipleInputSkeleton from 'components/SkeletonView/MultipleInputSkeleton'
import { useStores } from 'stores/utils/hooks/useStores'
import { Debtor } from 'containers/PersonalForm/PersonalForm.interface'

const ActiveLoansSkeleton = ({ debtor = Debtor.PRIMARY }) => {
  const {
    traversal: {
      data: { debtors }
    }
  } = useStores()

  const loanCount = debtors[debtor].numberOfActiveLoans

  if (loanCount === 0) {
    return <MultipleInputSkeleton count={1} />
  }

  return <MultipleInputSkeleton count={loanCount * 6} />
}

const MemoizedActiveLoansSkeleton = memo(ActiveLoansSkeleton)

// eslint-disable-next-line react/display-name
const ActiveLoansSkeletonFunc = (debtor: Debtor) => () =>
  <MemoizedActiveLoansSkeleton debtor={debtor} />

export default ActiveLoansSkeletonFunc
