import { memo } from 'react'

import CreateFinalAfterInactivity, {
  InactivityType,
  LONG_TIMEOUT,
  SHORT_TIMEOUT
} from './containers/CreateFinalAfterInactivity'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import {
  CONFIG_DDF_SEPARATED_DAC,
  CONFIG_DDF_SMAVA_SEPARATED_DAC,
  isConfigWithoutInactivityRedirection
} from 'config/utils/config'
import { Experiments } from 'CustomOptimizelyProvider'
import { PossibleVariants } from 'utils/tracking/optimizely/types'
import { getAge } from 'utils/date'

type InactivityProps = {
  handleSubmit: () => void
}

const InactivityContainer = ({ handleSubmit }: InactivityProps) => {
  const {
    rootStore: {
      page: { config },
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { birthday }
            }
          }
        }
      },
      experimentData
    }
  } = useRootStore()

  const isCreateFinalAfterInactivityFeatureEnabled =
    !isConfigWithoutInactivityRedirection(config.name)

  if (!isCreateFinalAfterInactivityFeatureEnabled) {
    return null
  }

  const isTouchedIgnored =
    config.name === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
    config.name === CONFIG_DDF_SEPARATED_DAC

  let timeout = SHORT_TIMEOUT

  const variationDac7 = experimentData[Experiments.CAT_2943_DAC_7_AGE_GROUP]
  const debtorsAge = getAge(birthday)

  if (
    variationDac7 === PossibleVariants.VARIATION_B &&
    debtorsAge >= 18 &&
    debtorsAge < 34
  ) {
    timeout = 180000
  }

  return (
    <>
      <CreateFinalAfterInactivity
        timeout={timeout}
        inactivityType={InactivityType.PAYOUT_ACCOUNT_INACTIVITY}
        onNext={handleSubmit}
        isTouchedIgnored={isTouchedIgnored}
      />
      <CreateFinalAfterInactivity
        timeout={LONG_TIMEOUT}
        inactivityType={InactivityType.DAC_INACTIVITY}
        onNext={handleSubmit}
        isTouchedIgnored={isTouchedIgnored}
      />
    </>
  )
}

export default memo(InactivityContainer)
