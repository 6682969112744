import { AxiosError, AxiosInstance } from 'axios'
import TraversalStore from 'stores/TraversalStore'
import { logWarning } from 'utils/log'
import { HttpStatusCode } from 'utils/url/status'
import {
  RegisteredEventName,
  broadcastToParent
} from '@finanzcheck/catalyst-pollard'
import { getEnv } from 'utils/env'

import { Interceptor } from '.'

const MIN_TIME_BETWEEN_TRAVERSAL_CREATIONS = 5000

/**
 * This interceptor will create a new traversal
 * if apollo responds with 401 errors.
 */
export default class TraversalRecovery implements Interceptor {
  private traversal: TraversalStore
  private isHandlingError = false
  private timer: number | null = null

  constructor(traversal: TraversalStore) {
    this.traversal = traversal
  }

  public static create(traversal: TraversalStore) {
    return new TraversalRecovery(traversal)
  }

  private resetTimer = () => {
    this.timer = null
  }

  private createNewTraversal = async () => {
    const traversal = await this.traversal.create()

    logWarning(`A new traversal was created -> ${traversal.data.result?.id}`)

    this.timer = window.setTimeout(
      this.resetTimer,
      MIN_TIME_BETWEEN_TRAVERSAL_CREATIONS
    )

    return traversal
  }

  /**
   * We don't want to handle errors that are thrown inside this interceptor.
   * This would make us handle our own errors and create an infinite loop.
   * This might happen when the server always responds with `401`,
   * even when creating a new traversal.
   */
  private withoutInterceptor = async <T>(method: () => Promise<T>) => {
    this.isHandlingError = true
    const result = await method()
    this.isHandlingError = false

    return result
  }

  public apply = (instance: AxiosInstance) => {
    instance.interceptors.response.use(undefined, async (error: AxiosError) => {
      if (
        error?.config?.url &&
        error?.config?.url.indexOf('finalize') > -1 &&
        HttpStatusCode.BadRequest
      ) {
        return Promise.reject(error)
      }

      // if there is an error with activity call send an error so that the call is retried in retry.js
      if (
        error?.config?.url &&
        (error?.config.url.indexOf('activity') > -1 ||
          error?.config.url.indexOf('person') > -1) &&
        HttpStatusCode.NotFound
      ) {
        return Promise.reject(error)
      }

      if (error?.response?.status === HttpStatusCode.Unavailable) {
        broadcastToParent({
          eventName: RegisteredEventName.updateCurrentImodTraversal,
          data: {
            advertisementId: '',
            leadIds: [],
            resumeHash: '',
            traversalId: ''
          }
        })
        broadcastToParent({
          eventName: RegisteredEventName.redirect,
          data: { url: getEnv('REACT_APP_WEBSITE_URL', true) }
        })
        return null
      }

      if (this.isHandlingError) {
        return Promise.resolve()
      }

      if (this.timer) {
        logWarning('Tried to create a new traversal too quickly 🦊')
        return Promise.reject(error)
      }

      const traversal = this.withoutInterceptor(this.createNewTraversal)

      return Promise.resolve(traversal)
    })

    return instance
  }
}
