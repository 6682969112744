import { NavigateFunction } from 'react-router-dom'
import RootStore from 'stores/RootStore'
import { UrlParams } from 'utils/url'
import { StartupCommand } from './command.interface'

export interface FfgConfProduct {
  id?: number // currently not available in Taurine
  name?: string // currently not available in Taurine
  bankName?: string // currently not available in Taurine
  amount?: number
  term?: number
  purpose?: string
  purposeText?: string // currently not available in Taurine
  interest?: number // currently not available in Taurine
  monthlyRate?: number // currently not available in Taurine
  total?: number // currently not available in Taurine
}

export interface FfgConf {
  affiliateId?: number // affiliate >2000, ownfiliate >1000,
  bid?: string // browser tab id, 32 chars
  sid?: string // = fcId, 24 chars
  leadID?: string // hashed cafe activity id, 12 chars
  subID?: string // affiliate subID, optional
  resumehash?: string // char 0-9, may be removed (simon appelt)
  configurationId?: string // in taurine = system.advertisementId
  preUrl?: string // backUrl oder referrer
  refID?: string // in taurine system.refId
  externalID?: string // in taurine system.externalId
  flow?: 'imod'
  product?: FfgConfProduct
  step?: string
}

export interface Ffg {
  conf?: FfgConf
}

declare global {
  interface Window {
    ffg?: Ffg
  }
}

export function updateFfgConf<T extends keyof FfgConf>(
  key: T,
  value: FfgConf[T]
) {
  if (!window.ffg?.conf) {
    return
  }

  window.ffg.conf[key] = value
}

export class InitFfgConfCommand implements StartupCommand {
  name = 'InitFfgConfig'
  dependencies = ['LoadConfiguration']
  timeout = 10000

  // eslint-disable-next-line class-methods-use-this
  execute = async (
    {
      navigation: { currentPageInSession },
      traversal: {
        data: {
          system: {
            advertisementId,
            affiliateId,
            refId: systemRefId,
            subId: systemSubId,
            leadId,
            externalId: systemExternalId
          },
          common: { amount, term, purpose }
        }
      }
    }: RootStore,
    _navigate: NavigateFunction,
    { refId, subId, externalId, resume }: UrlParams
  ) => {
    window.ffg = {
      conf: {
        affiliateId,
        bid: undefined,
        sid: undefined,
        leadID: leadId,
        subID: subId || systemSubId,
        resumehash: resume ? resume.substr(0, 10) : '',
        configurationId: advertisementId,
        preUrl: window.document.referrer,
        refID: refId || systemRefId,
        externalID: externalId || systemExternalId,
        flow: 'imod',
        product: {
          id: undefined,
          name: undefined,
          bankName: undefined,
          amount,
          term,
          purpose,
          purposeText: undefined,
          interest: undefined,
          monthlyRate: undefined,
          total: undefined
        },
        step: currentPageInSession || ''
      }
    }
  }
}

export default InitFfgConfCommand
