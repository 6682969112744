import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { logInfo } from 'utils/log'
import { LoadingMode } from 'components/LoadingProvider'
import { scrollToTop } from 'utils/pollard/scroll'
import { updateFfgConf } from 'startup/command/initFfgConf'
import { getPageNameFromPath, getPreviousPageName } from 'utils/pages'
import { action, makeObservable, observable, reaction } from 'mobx'
import Bugsnag from '@bugsnag/js'
import { AxiosError, AxiosResponse } from 'axios'
import { RootObject } from 'interfaces/ApolloResponse.interface'
import {
  AmplitudeEvent,
  FinalRedirectStatus,
  UserProperty
} from 'utils/tracking/amplitude/amplitude.interface'
import { HttpStatusCode } from 'utils/url/status'
import { setUserProperties, trackInAmplitude } from 'utils/tracking/amplitude'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import FinalizeError from 'error/finalizeError'
import { isAxiosError } from 'api/utils'
import { trackLead } from 'utils/tracking'
import RootStore from 'stores/RootStore'
import pollard from 'utils/pollard'
import { getStorage } from 'utils/storage'
import { NavigateFunction } from 'react-router-dom'
import { PageName } from './Navigation.interface'
import { Debtor } from 'containers/Smava/PersonalFormPrimary/PersonalForm.interface'

const AUTO_PROCEED_PAGE = 'loadingLead'

export default class Navigation {
  private rootStore: RootStore

  public stack: string[] = []
  // string is deprecated, please use PageName
  public currentPageInSession: string | null | PageName = null
  public currentConfigInSession: string | null = null
  // The page, that the customer has seen first, when entering Taurine
  public initiallySeenPage: string | null = null
  public isExistingUser?: boolean
  public tenantContext?: string
  public parentUrl = ''

  constructor(rootStore: RootStore) {
    makeObservable<Navigation>(this, {
      stack: observable,
      handleFinalizeErrors: action,
      currentPageInSession: observable,
      currentConfigInSession: observable,
      initiallySeenPage: observable,
      setCurrentConfigInSession: action,
      setCurrentPageInSession: action,
      setInitiallySeenPage: observable,
      parentUrl: observable,
      setParentUrl: action
    })

    this.rootStore = rootStore
    this.parentUrl = ''

    this.rootStore.history.listen(({ location }) => {
      const { setNavigation, config } = this.rootStore.page

      if (!config.version) {
        return
      }

      const pageFromPath = getPageNameFromPath(location.pathname)

      if (!config.pages[pageFromPath]) {
        return
      }

      this.setCurrentPageInSession(pageFromPath)
      setNavigation(pageFromPath)
      this.pushToStack(pageFromPath)
    })

    /**
     * Every time the traversal data changes,
     * it might affect conditions that are in the config,
     * e.g. debtors.relationship === 'SPOUSE' -> `/civilStatus` will not be rendered.
     * Therefore, if the next page was `/civilStatus` it needs to be updated.
     */
    reaction(
      () => this.rootStore.traversal.data,
      () => {
        if (!this.currentPageInSession) {
          return
        }
        this.rootStore.page.setNavigation(this.currentPageInSession)
      }
    )
  }

  static create(rootStore: RootStore): Navigation {
    return new Navigation(rootStore)
  }

  private pushToStack = (fromPage: string) => {
    this.stack = [...this.stack, fromPage]

    Bugsnag.addMetadata('navigation', {
      stack: this.stack
    })
  }

  setCurrentConfigInSession = (configName: string | null) => {
    this.currentConfigInSession = configName
    getStorage('sessionStorage').setItem('configName', configName || '')

    this.broadcastNavigationStateToParent({
      currentConfig: configName
    })
  }

  setParentUrl = async () => {
    const data = await pollard.request(
      {
        eventName: RegisteredEventName.getParentFrameUrl
      },
      5000
    )
    if (data) {
      const url = new URL(data as string)
      const searchParams = new URLSearchParams(url.search)
      const utmCampaign = searchParams.get('utm_campaign')
      const utmParameter = searchParams.get('utm_parameters')
      const utmMedium = searchParams.get('utm_medium')
      const utmSource = searchParams.get('utm_source')

      setUserProperties({
        ...(utmSource && { [UserProperty.UtmSource]: utmSource }),
        ...(utmCampaign && { [UserProperty.UtmCampaign]: utmCampaign }),
        ...(utmParameter && { [UserProperty.UtmParameters]: utmParameter }),
        ...(utmMedium && { [UserProperty.UtmMedium]: utmMedium })
      })
    }
    this.parentUrl = data as string
  }

  setEmailFieldDisabled = (debtor: string): boolean => {
    const debtorData = this.rootStore.traversal.data.debtors[debtor]
    const phoneNumberError = this.rootStore.fieldErrors.pathHasError(
      `debtors.${debtor}.contact.phoneNumber`
    )
    const emailError = this.rootStore.fieldErrors.pathHasError(
      `debtors.${debtor}.contact.email`
    )

    const activeElement = document.activeElement

    if (
      // @ts-ignore: Unreachable code error
      activeElement.name === `debtors.${debtor}.contact.email`
    ) {
      return false
    }

    if (
      this.rootStore.traversal.data.common.hasAcceptedTerms &&
      debtorData.personal.firstname &&
      debtorData.personal.lastname &&
      debtorData.contact.email &&
      debtorData.contact.phoneNumber &&
      !phoneNumberError &&
      !emailError
    ) {
      return true
    }
    if (debtor === Debtor.SECONDARY) {
      if (
        debtorData.contact &&
        debtorData.contact.email &&
        debtorData.contact.phoneNumber &&
        !phoneNumberError &&
        !emailError
      ) {
        return true
      }
    }

    return false
  }

  setCurrentPageInSession = (pageName: string | null) => {
    this.currentPageInSession = pageName

    this.broadcastNavigationStateToParent({
      currentPageName: pageName
    })
  }

  setInitiallySeenPage = (initialPage: string | null) => {
    this.initiallySeenPage = initialPage

    this.broadcastNavigationStateToParent({
      initiallySeenPage: initialPage
    })
  }

  setTenantContext = async () => {
    if (!this.tenantContext) {
      const data = await pollard.request(
        {
          eventName: RegisteredEventName.getTenantContext
        },
        5000
      )
      this.tenantContext = data as string
    }
  }

  getPreviousNavigation = () =>
    this.stack.length > 1 ? this.stack[this.stack.length - 2] : null

  private broadcastNavigationStateToParent = (
    data: Record<string, unknown>
  ) => {
    broadcastToParent({
      eventName: RegisteredEventName.setCurrentNavigation,
      data: {
        system: 'taurine',
        url: window.location.origin + window.location.pathname,
        currentConfig: this.currentConfigInSession,
        currentPageName: this.currentPageInSession,
        initiallySeenPage: this.initiallySeenPage,
        ...data
      }
    })
  }

  private shouldNavigateToBackUrl = () => {
    const {
      isInIframe,
      page: { getCurrentIndex },
      urlParams: { backUrl }
    } = this.rootStore

    if (!isInIframe || !backUrl) {
      return false
    }

    if (getCurrentIndex() === 0) {
      return true
    }

    return this.currentPageInSession === this.initiallySeenPage
  }

  goToPreviousPage = (navigate: NavigateFunction) => {
    const {
      setLoadingMode,
      page: { removeErrorsForNextPage, previousPageName }
    } = this.rootStore

    trackInAmplitude(AmplitudeEvent.BackButtonIsClicked)

    if (this.shouldNavigateToBackUrl()) {
      logInfo('Navigating to "backUrl"..')

      broadcastToParent({
        eventName: RegisteredEventName.backLink,
        data: null
      })

      // in case the pollard communcation doesn't work
      // TODO: We can use a Pollard request here to ensure a response :)
      setTimeout(() => setLoadingMode(LoadingMode.None), 5000)

      return
    }

    if (!previousPageName) {
      setLoadingMode(LoadingMode.None)

      return
    }

    // Don't push page that automatically proceeds to history, replace with page before that
    if (previousPageName === AUTO_PROCEED_PAGE) {
      const skippedPreviousPageName = getPreviousPageName(
        previousPageName,
        this.rootStore
      )
      if (!skippedPreviousPageName) {
        return
      }
      this.rootStore.history.replace(skippedPreviousPageName)
      navigate(skippedPreviousPageName, { replace: true })
    } else {
      this.rootStore.history.push(previousPageName)
      navigate(previousPageName, { replace: true })
    }

    this.scrollToTop()
    removeErrorsForNextPage()

    updateFfgConf('step', previousPageName)

    setLoadingMode(LoadingMode.None)
  }

  handleFinalizeErrors = (
    error: Error | AxiosError<RootObject> | undefined
  ) => {
    if (!isAxiosError(error)) {
      throw error
    }

    const amplitudeEvent = { status: FinalRedirectStatus.Failed }

    if (error.response?.status === HttpStatusCode.BadRequest) {
      this.rootStore.fieldErrors.setFieldErrorsFromAxiosResponse(
        error.response as AxiosResponse<RootObject<unknown>, unknown>
      )

      trackInAmplitude(AmplitudeEvent.GoToFinal, {
        ...amplitudeEvent,
        isValidationError: true
      })

      return
    }

    trackInAmplitude(AmplitudeEvent.GoToFinal, {
      ...amplitudeEvent,
      isValidationError: false
    })

    if (error.config) {
      logAndTrackError(
        new FinalizeError('Finalization failed', error.config, error.response)
      )
    }
  }

  goToNextPage = async (navigate: NavigateFunction) => {
    const {
      setLoadingMode,
      page: { nextPageName, isOnTermsAndConditionsPage },
      traversal: { finalize, hasAcceptedTermsAndConditions }
    } = this.rootStore

    if (!nextPageName) {
      await finalize(this.handleFinalizeErrors)
      return
    }

    if (
      hasAcceptedTermsAndConditions() &&
      (await isOnTermsAndConditionsPage())
    ) {
      await trackLead(this.rootStore)
    }

    this.scrollToTop()

    updateFfgConf('step', nextPageName)

    // Don't push page that automatically proceeds to history, replace with page after that
    if (this.currentPageInSession === AUTO_PROCEED_PAGE) {
      this.rootStore.history.replace(nextPageName)
      navigate(nextPageName, { replace: true })
    } else {
      this.rootStore.history.push(nextPageName)
      navigate(nextPageName, { replace: true })
    }

    setLoadingMode(LoadingMode.None)
  }

  scrollToTop = () => scrollToTop(this.rootStore.isInIframe)
}
