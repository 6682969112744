import { useEffect, memo, useCallback } from 'react'
import { observer } from 'mobx-react'

import { useSaveButton } from 'hooks/useSaveButton'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useStores } from 'stores/utils/hooks/useStores'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import {
  CONFIG_DDF_SEPARATED_DAC,
  isMarketingConfig
} from 'config/utils/config'
import { NavigateFunction } from 'react-router-dom'
import LegalHintTexts from 'texts/common/copy/legal'
import {
  RegisteredEventName,
  broadcastToParent
} from '@finanzcheck/catalyst-pollard'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import { DACEvent } from 'components/DigitalAccountCheck'
import { DacWidgetTabEnum } from 'stores/TraversalStore'
import { usePayoutAccountValidation } from 'hooks/usePayoutAccountValidation'
import { getButtonSizes } from './getButtonSizes'
import FormNavigation from './FormNavigationTemplate'

type Props = {
  onBack?: () => void
  onNext: () => void
  legalContact: string
  navigate: NavigateFunction
}

const FFGFormNavigation = observer(({ onBack, onNext, navigate }: Props) => {
  const {
    rootStore: {
      isInIframe,
      urlParams: { backUrl },
      page: { config }
    }
  } = useRootStore()

  const {
    page: { isFirstDisplayedPage, isLastDisplayedPage, getCurrentIndex },
    navigation: { currentPageInSession },
    traversal: { data, dacWidgetState, dacWidgetTab }
  } = useStores()

  const isPayoutAccountReady = usePayoutAccountValidation(true)

  const { isSaveButtonVisible } = useSaveButton({
    onSuccess: () => {},
    onError: () => {}
  })

  const currentIndex = getCurrentIndex()

  const trackWithOrigin = (event: AmplitudeEvent, payload = {}) => {
    trackInAmplitude(event, { origin: 'taurine', ...payload })
  }

  const onNextWithTracking = useCallback(() => {
    if (
      dacWidgetTab === DacWidgetTabEnum.SLOW &&
      currentPageInSession === 'payoutAccount'
    ) {
      trackWithOrigin(AmplitudeEvent.OnDacStateChange, {
        state: 'disagreed' as FintecState
      })
      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event: DACEvent.STATE_CHANGE_DISAGREED,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            ...getOptimizelyTrackingAttribute(data)
          }
        }
      })
    }

    onNext()
  }, [onNext, dacWidgetTab, data])

  const isLastPage = isLastDisplayedPage(currentPageInSession)

  useEffect(() => {
    if (
      isLastPage &&
      config.name === CONFIG_DDF_SEPARATED_DAC &&
      dacWidgetState === 'success'
    ) {
      trackWithOrigin(AmplitudeEvent.OnDACSuccessAutoRedirect)
      onNextWithTracking()
    }
  }, [dacWidgetState, onNextWithTracking, config.name, isLastPage])

  const isFirstPage = isFirstDisplayedPage(currentPageInSession)

  const backLabel =
    !isInIframe || currentIndex > 0 || backUrl ? 'Zurück' : undefined

  const isDACStepInDACSeparatedConfig =
    isLastPage &&
    config.name === CONFIG_DDF_SEPARATED_DAC &&
    dacWidgetState !== 'success'

  const isBackButtonHidden =
    !backLabel || isFirstPage || isDACStepInDACSeparatedConfig

  const submitLabel = isLastPage ? 'Angebote ansehen' : 'Weiter'

  const { sizeOfContinueButton, sizeOfBackButton, sizeOfSaveButton } =
    getButtonSizes(isBackButtonHidden, isSaveButtonVisible)

  const isInDACHardRequirement = isMarketingConfig(config.name)

  const isContinueButtonHiddenOnDac =
    (currentPageInSession === 'payoutAccount' &&
      dacWidgetTab === DacWidgetTabEnum.QUICK &&
      isPayoutAccountReady &&
      dacWidgetState !== 'success' &&
      isInDACHardRequirement) ||
    isDACStepInDACSeparatedConfig

  const isContinueWithoutDacVisible = isDACStepInDACSeparatedConfig

  return (
    <FormNavigation
      onBack={onBack}
      onNext={onNext}
      navigate={navigate}
      legalText={LegalHintTexts.defaultLegalHint}
      isContinueWithoutDacVisible={isContinueWithoutDacVisible}
      isBackButtonHidden={isBackButtonHidden}
      isContinueButtonHidden={isContinueButtonHiddenOnDac}
      sizeOfContinueButton={sizeOfContinueButton}
      sizeOfBackButton={sizeOfBackButton}
      sizeOfSaveButton={sizeOfSaveButton}
      submitLabel={submitLabel}
      backLabel={backLabel}
      onNextWithTracking={onNextWithTracking}
      trackWithOrigin={trackWithOrigin}
      continueButtonColor="secondary"
      backButtonVariant="text"
      savekButtonVariant="contained"
    />
  )
})

export default memo(FFGFormNavigation)
