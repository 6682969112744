import { TrackEvent, TransactionEvent } from 'utils/tracking/tracking.interface'
import BugsnagError, { Metadata } from './bugsnagError'

export default class TrackingError extends BugsnagError {
  public event: TrackEvent | TransactionEvent

  constructor(message: string, event: TrackEvent | TransactionEvent) {
    super(message)

    this.name = 'TrackingError'
    this.event = event
  }

  getMetadata(): Metadata {
    return {
      tracking: {
        event: this.event
      }
    }
  }
}
