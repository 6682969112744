import { AxiosResponse } from 'axios'
import { omit, flatten } from 'rambdax'
import { observable, action, toJS, makeObservable, runInAction } from 'mobx'
import { getDisplayedPageNames } from 'utils/pages'
import {
  RootObject,
  TraversalError
} from '../interfaces/ApolloResponse.interface'
import { convertSlashToDotted } from '../utils/objectPath'
import RootStore from './RootStore'
import { getFailuresOfAxiosResponse } from './utils/validation'

export interface TraversalErrors {
  [path: string]: TraversalError[]
}

class FieldErrorStore {
  private rootStore: RootStore

  public errors: TraversalErrors = {}
  public isErrorOnFinalize = false

  constructor(rootStore: RootStore) {
    makeObservable<FieldErrorStore>(this, {
      errors: observable,
      check: action,
      removeErrors: action,
      removePathValuesWithErrors: action,
      removePagePathValuesWithErrors: action,
      removePageErrors: action,
      setFieldErrorsFromAxiosResponse: action,
      setIsFinalizeError: observable
    })

    this.rootStore = rootStore
  }

  private getErrorsFromResponse = async (
    response: AxiosResponse<RootObject>,
    includedPaths?: string[]
  ) => {
    const knownPaths = await this.rootStore.page.getKnownPaths()
    const included = includedPaths || knownPaths

    return getFailuresOfAxiosResponse(response).reduce<TraversalErrors>(
      (errors, { fields, ...traversalError }) => {
        for (const fieldName of Object.values(fields)) {
          const path = convertSlashToDotted(fieldName)

          if (!knownPaths.includes(path)) {
            continue
          }

          if (!included.includes(path)) {
            continue
          }

          if (!errors[path]) {
            // eslint-disable-next-line no-param-reassign
            errors[path] = []
          }

          if (
            errors[path].findIndex((error) => error.id === traversalError.id) >
            -1
          ) {
            continue
          }

          errors[path].push(traversalError)
        }

        return errors
      },
      {}
    )
  }

  check = async (
    response: AxiosResponse<RootObject>,
    { include, merge = false }: { include?: string[]; merge?: boolean } = {}
  ) => {
    const fieldErrors = await this.getErrorsFromResponse(response, include)

    runInAction(() => {
      this.errors = merge
        ? { ...this.errors, ...fieldErrors }
        : { ...fieldErrors }
    })

    return this
  }

  get = (path: string) => this.errors[path] || []

  getErrors = async (pageName: string): Promise<TraversalErrors> => {
    const { config } = this.rootStore.page

    if (!config.version) {
      return {}
    }

    const pagePaths = await this.rootStore.page.getPaths(pageName)

    return pagePaths.reduce<TraversalErrors>((errors, path) => {
      const fieldError = this.get(path)
      if (fieldError.length > 0) {
        // eslint-disable-next-line no-param-reassign
        errors[path] = toJS(fieldError)
      }

      return errors
    }, {})
  }

  pathHasError = (path: string) => Boolean(this.get(path).length)

  findFirstErrorPage = async (
    maxIndex?: number
  ): Promise<string | undefined> => {
    const pages = getDisplayedPageNames(this.rootStore).slice(0, maxIndex)
    const errors = await Promise.all(pages.map(this.getErrors))
    const errorIndex = errors.findIndex(
      (fieldErrors) => Object.keys(fieldErrors).length > 0
    )

    return pages[errorIndex]
  }

  removeErrors = (path: string) => {
    runInAction(() => {
      this.errors = omit([path], this.errors)
    })
  }

  resetPathValueWithPatch = (path: string, patch = true) => {
    this.rootStore.traversal.handlePathFormFieldChange(path, undefined, patch)
  }

  removePathValuesWithErrors = (errors: TraversalErrors) => {
    runInAction(() => {
      for (const pathWithError in errors) {
        this.resetPathValueWithPatch(pathWithError, false)
      }
    })
  }

  removePagePathValuesWithErrors = async (pageName: string) => {
    const pathsFromPage = await this.rootStore.page.getPaths(pageName)

    runInAction(() => {
      for (const pathWithError in this.errors) {
        if (pathsFromPage.includes(pathWithError)) {
          this.resetPathValueWithPatch(pathWithError)
        }
      }
    })
  }

  removePageErrors = async (pageName: string) => {
    const pathsFromPage = await this.rootStore.page.getPaths(pageName)

    runInAction(() => {
      this.errors = omit(pathsFromPage, this.errors)
    })
  }

  setIsFinalizeError = (isError: boolean) => {
    this.isErrorOnFinalize = isError
  }

  setFieldErrorsFromAxiosResponse = async (
    response: AxiosResponse<RootObject>,
    merge = false
  ) => {
    const pathsToCheck = Array.from(
      new Set(
        flatten<string>(
          await Promise.all(
            getDisplayedPageNames(this.rootStore)
              .slice(0, this.rootStore.page.getCurrentIndex() + 1)
              .map(this.rootStore.page.getPaths)
          )
        )
      )
    )

    await this.check(response, { include: pathsToCheck, merge })
  }
}

export default FieldErrorStore
