import { getClient } from 'api'
import { retry } from 'api/utils/retry'
import { HttpStatusCode } from 'utils/url/status'

interface ActivityResponse {
  result: {
    activityId: string
  }
}

export const getActivityId = async (
  advertisementId: string,
  traversalId: string
) =>
  retry<ActivityResponse>(
    getClient('apollo'),
    {
      method: 'get',
      url: `/activity/${advertisementId}/${traversalId}`
    },
    {
      maxRetryCount: 10,
      retryInterval: 300,
      condition: {
        error: (error) => error?.response?.status === HttpStatusCode.NotFound
      }
    }
  )
