import { Theme } from '@mui/material/styles'
import {
  FormControlLabel,
  Radio as MuiRadio,
  Box,
  RadioProps
} from '@mui/material'
import { memo } from 'react'

import useEmotionStyles from 'hooks/useEmotionStyles'
import * as CSSTypes from 'csstype'
import { Choice } from './radio.interface'
import { RadioLabel } from './RadioLabel'

interface StyleProps {
  hasError: boolean
  isSelected: boolean
}

const getBorder = (theme: Theme, { hasError, isSelected }: StyleProps) => {
  if (hasError) {
    return `1px solid ${theme.palette.error.main}`
  }

  if (isSelected) {
    return `1px solid ${theme.palette.primary.main}`
  }

  return `1px solid ${theme.palette.grey[300]}`
}

const styles = (theme: Theme, { hasError, isSelected }: StyleProps) => ({
  root: {
    borderRadius: '2px',
    border: getBorder(theme, { hasError, isSelected }),
    boxShadow: isSelected ? `0 0 2px ${theme.palette.primary.main}` : 'red',
    '&:hover': {
      border: `1px solid ${
        hasError ? theme.palette.error.main : theme.palette.primary.main
      }`
    },
    minHeight: '48px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  rootRadio: {
    color: theme.palette.grey[600]
  },
  formLabelRadio: {
    width: '100%',
    margin: 0
  },
  radioLabel: {
    whiteSpace: 'break-spaces' as CSSTypes.Property.WhiteSpace
  }
})

interface SmavaRadioProps<T> extends RadioProps {
  choice: Choice<T>
  isSelected: boolean
  hasError: boolean
}

function SmavaRadio<T>({
  choice,
  isSelected,
  hasError,
  color = 'primary'
}: SmavaRadioProps<T>) {
  const classes = useEmotionStyles<ReturnType<typeof styles>, StyleProps>(
    styles,
    { hasError, isSelected }
  )

  return (
    <Box
      className={classes.root}
      bgcolor={isSelected ? 'primary.100' : 'white'}
      mb={1}
    >
      <FormControlLabel
        value={choice.value !== undefined ? `${choice.value}` : undefined}
        control={
          <MuiRadio color={color} classes={{ root: classes.rootRadio }} />
        }
        label={
          <span className={classes.radioLabel}>
            <RadioLabel choice={choice} />
          </span>
        }
        classes={{ root: classes.formLabelRadio }}
      />
    </Box>
  )
}

export default memo(SmavaRadio) as typeof SmavaRadio
