import Typography from '@mui/material/Typography'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import NoSimIcon from '@mui/icons-material/NoSim'
import AddressSkeleton from 'components/Address/AddressSkeleton'
import ActiveLoansSkeleton from 'components/ActiveLoans/ActiveLoansSkeleton'
import { Unit } from 'components/Input/Text'
import { LoadingLeadVariantEnum } from 'components/LoadingLead'
import PersonalFormSkeleton from 'containers/PersonalForm/PersonalFormSkeleton'
import PersonalSkeleton from 'containers/PersonalSkeleton'
import HouseholdSkeleton from 'containers/HouseholdSkeleton'
import PropertySkeleton from 'containers/PropertySkeleton'
import IncomeAllSkeleton from 'containers/IncomeAllSkeleton'
import ExpensesSkeleton from 'containers/ExpensesSkeleton'
import KfzFormSkeleton from 'containers/KfzForm/KfzFormSkeleton'
import EmploymentPositionSkeleton from 'containers/EmploymentPositionSkeleton'
import hasSecondDebtor from 'utils/formDependencies/conditions/hasSecondDebtor'
import isNotSpouse from 'utils/formDependencies/conditions/isNotSpouse'
import canHaveMinijob from 'utils/formDependencies/conditions/canHaveMinijob'
import { TwoDebtorsNudge } from 'components/Nudges/DebtorCount/TwoDebtorsNudge'
import { EmploymentPositionNudge } from 'components/Nudges/EmploymentPosition/EmploymentPositionNudge'
import { SingleDebtorCountNudge } from '../components/Nudges/DebtorCount/SingleDebtorCountNudge'
import RootStore from '../stores/RootStore'
import {
  reactionClearMortgageRateUsedProperty,
  reactionClearSecondaryLivingSituation,
  reactionResetHasMiniJob,
  reactionResetRentalProperty,
  reactionSetAmoutVehiclePriceInitialPayment
} from './utils/reactions'
import { CONFIG_DDF_SMAVA } from './utils/config'
import { LoadedConfig } from './config.interface'
import {
  LivingSituation,
  Vehicle
} from '../interfaces/ApolloResponse.interface'
import { Debtor } from '../containers/PersonalForm/PersonalForm.interface'
import SchufaGreenLogo from '../components/SchufaGreenLogo'
import { showEmploymentPage } from './utils/conditions'

export const ddfIntroductionStyleProps = {
  pathStyleProps: {
    withAlternativeTitle: true,
    useInfoAlternativeIfAvailable: true
  },
  introductionStyleProps: {
    isLightBackgroundVariant: true,
    isSlimVariant: true
  }
}

export default {
  name: CONFIG_DDF_SMAVA,
  version: '2.0',
  pages: {
    base: {
      components: {
        headline: {
          component: Typography,
          props: {
            variant: 'h4',
            children: 'Mit smava Kredite von über 20 Partnern vergleichen'
          }
        },
        purpose: {
          lazy: () => import('../components/PathSelect'),
          props: {
            path: 'common.purpose',
            useSimpleSelect: true,
            handleLoanSelectorChanges: true
          }
        },
        amount: {
          lazy: () => import('../components/Smava/PathAmountSelect'),
          props: {
            path: 'common.amount'
          }
        },
        kaufPreis: {
          lazy: () => import('../components/PathAmountInput'),
          props: {
            path: 'common.vehicle.price',
            unit: Unit.EURO,
            isTooltipHidden: true,
            smallTextField: true,
            outerLabel: true,
            isVehiclePriceCalculated: true
          }
        },
        anzhalung: {
          lazy: () => import('../components/PathAmountInput'),
          props: {
            path: 'common.vehicle.initialPayment',
            unit: Unit.EURO,
            isTooltipHidden: true,
            smallTextField: true,
            outerLabel: true,
            isVehiclePriceCalculated: true
          }
        },
        term: {
          lazy: () => import('../components/PathSelect'),
          props: {
            path: 'common.term',
            useSimpleSelect: true
          }
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () => traversal.data.common.vehicle,
          method: (vehicle: Vehicle) => {
            reactionSetAmoutVehiclePriceInitialPayment(traversal, vehicle)
          }
        }
      ]
    },
    debtorCount: {
      components: {
        headlineWithImage: {
          lazy: () => import('../components/HeadlineWithImage'),
          props: {
            variant: 'h4',
            children: 'Ihr verlässlicher Kreditüberblick von über 20 Banken',
            image: SchufaGreenLogo
          }
        },
        debtor_count_nudge: {
          component: SingleDebtorCountNudge,
          condition: ({
            traversal: {
              data: {
                common: { purpose }
              }
            }
          }) => purpose === 'REFINANCING' || purpose === 'PRE_OWNED_CAR',
          props: {
            dependson: 'isSingleDebtor',
            text: ''
          }
        },
        info_overview: {
          component: Typography,
          props: {
            component: 'p',
            children:
              'Um die besten Kreditangebote für Sie zu ermitteln, benötigt smava einige Informationen zu Ihrer Person und Ihrer finanziellen Situation.'
          }
        },
        debtorTitle: {
          lazy: () => import('../components/Smava/DebtorTitle')
        },
        debtorChoice: {
          lazy: () => import('../components/DebtorChoice'),
          props: {
            outlinedChoice: true,
            showTooltipOnFieldClick: true
          }
        },
        two_debtors_nudge: {
          component: TwoDebtorsNudge,
          props: {
            dependson: 'hasSecondDebtor'
          }
        },
        debtorRelationship: {
          lazy: () => import('../components/DebtorRelationship'),
          props: {
            outlinedChoice: true,
            showTooltipOnFieldClick: true
          }
        }
      }
    },
    civilStatus: {
      components: {
        headline: {
          component: Typography,
          props: {
            variant: 'h4',
            children: 'Persönliche Angaben'
          }
        },
        civilStatus_primary: {
          lazy: () => import('../components/Smava/CivilStatus'),
          props: {
            debtor: Debtor.PRIMARY,
            showTitle: true
          }
        },
        civilStatus_secondary: {
          lazy: () => import('../components/Smava/CivilStatus'),
          props: {
            debtor: Debtor.SECONDARY,
            showTitle: true
          }
        }
      },
      condition: isNotSpouse
    },
    employer: {
      condition: ({ traversal: { data } }) => data.debtors.count === 1,
      components: {
        employer: {
          lazy: () => import('../containers/EmploymentPosition'),
          props: {
            debtor: Debtor.PRIMARY,
            smallTextField: true,
            outerLabel: true,
            showTooltipOnFieldClick: true,
            titleText: 'Beruf',
            showIntroduction: true,
            useSimpleSelect: true,
            outlinedChoice: true,
            ...{ ...ddfIntroductionStyleProps }
          },
          skeleton: EmploymentPositionSkeleton
        },
        position_nudge: {
          component: EmploymentPositionNudge,
          props: {
            dependson: 'hasPositionNudgeRelevant'
          }
        },
        pensionFund: {
          lazy: () => import('../containers/PensionFund'),
          props: {
            debtor: Debtor.PRIMARY,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true
          }
        },
        minijob: {
          lazy: () => import('../components/PathYesNo'),
          props: {
            path: 'debtors.primary.hasMinijob',
            outlinedChoice: true,
            showTooltipOnFieldClick: true
          }
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () =>
            traversal.data.debtors.primary.currentEmployment.position,
          method: () =>
            reactionResetHasMiniJob(
              Debtor.PRIMARY,
              traversal.data.debtors.primary.currentEmployment.position,
              traversal.handlePathFormFieldChange
            )
        }
      ]
    },
    household_single_debtor: {
      condition: ({ traversal: { data } }) => data.debtors.count === 1,
      components: {
        household_primary: {
          lazy: () => import('../components/Smava/Household'),
          props: {
            debtor: Debtor.PRIMARY,
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            outlinedChoice: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            showPeopleContainer: false,
            useSimpleSelect: true,
            showTypeAsSelect: true
          },
          skeleton: HouseholdSkeleton
        }
      }
    },
    household: {
      condition: ({ traversal: { data } }) => data.debtors.count === 2,
      components: {
        commonHousehold: {
          lazy: () => import('../containers/CommonHousehold'),
          props: {
            outlinedChoice: true,
            showTooltipOnFieldClick: true
          }
        },
        household_primary: {
          lazy: () => import('../containers/Household'),
          props: {
            debtor: Debtor.PRIMARY,
            livingSituationSelect: true,
            ...{ ...ddfIntroductionStyleProps },
            outlinedChoice: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            showPeopleContainer: false,
            useSimpleSelect: true,
            isSquaredButtonStyle: true
          },
          skeleton: HouseholdSkeleton
        },
        household_secondary: {
          lazy: () => import('../containers/Household'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            livingSituationSelect: true,
            ...{ ...ddfIntroductionStyleProps },
            outlinedChoice: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            showPeopleContainer: false,
            useSimpleSelect: true,
            isSquaredButtonStyle: true
          },
          skeleton: HouseholdSkeleton
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () => traversal.data.debtors.isCommonHousehold,
          method: () =>
            reactionClearSecondaryLivingSituation(
              traversal.data.debtors.isCommonHousehold,
              traversal.data.debtors.secondary.household.livingSituation,
              traversal.handlePathFormFieldChange
            )
        },
        {
          field: () => traversal.data.debtors.primary.household.livingSituation,
          method: (livingSituation: LivingSituation) =>
            reactionClearMortgageRateUsedProperty(
              traversal,
              livingSituation,
              Debtor.PRIMARY
            )
        },
        {
          field: () =>
            traversal.data.debtors.secondary.household.livingSituation,
          method: (livingSituation: LivingSituation) =>
            reactionClearMortgageRateUsedProperty(
              traversal,
              livingSituation,
              Debtor.SECONDARY
            )
        }
      ]
    },
    household_residents_single_debtor: {
      condition: ({ traversal: { data } }) => data.debtors.count === 1,
      components: {
        household_resident_primary: {
          lazy: () => import('../components/Smava/HouseholdResident'),
          props: {
            debtor: Debtor.PRIMARY,
            ...{ ...ddfIntroductionStyleProps },
            showPeopleContainer: false,
            isSquaredButtonStyle: true,
            outlinedChoice: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            showLabelAndInfo: false,
            showTypeAsSelect: true
          }
        }
      }
    },
    realEstate: {
      condition: ({ traversal: { data } }) => data.debtors.count === 2,
      components: {
        property_primary: {
          lazy: () => import('../containers/Property'),
          props: {
            debtor: Debtor.PRIMARY,
            outerLabel: true,
            outlinedChoice: true,
            smallTextFiel: true,
            showTooltipOnFieldClick: true,
            showTypeAsSelect: true
          },
          skeleton: PropertySkeleton
        },
        property_secondary: {
          lazy: () => import('../containers/Property'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            outerLabel: true,
            outlinedChoice: true,
            smallTextFiel: true,
            showTooltipOnFieldClick: true,
            showTypeAsSelect: true
          },
          skeleton: PropertySkeleton
        }
      }
    },
    expenses: {
      condition: ({ traversal: { data } }) => data.debtors.count === 2,
      components: {
        expenses_primary: {
          lazy: () => import('../containers/Expenses'),
          props: {
            debtor: Debtor.PRIMARY,
            ...{ ...ddfIntroductionStyleProps },
            showExpenseCards: false,
            outerLabel: true,
            outlinedChoice: true,
            smallTextFiel: true,
            showTooltipOnFieldClick: true,
            isSquaredButtonStyle: true
          },
          skeleton: ExpensesSkeleton
        },
        expenses_secondary: {
          lazy: () => import('../containers/Expenses'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            ...{ ...ddfIntroductionStyleProps },
            showExpenseCards: false,
            outerLabel: true,
            outlinedChoice: true,
            smallTextFiel: true,
            showTooltipOnFieldClick: true,
            isSquaredButtonStyle: true
          },
          skeleton: ExpensesSkeleton
        }
      }
    },
    income_single_taxable: {
      condition: ({ traversal: { data } }) =>
        !(
          canHaveMinijob(Debtor.PRIMARY)(data) &&
          !data.debtors.primary.hasMinijob
        ) && data.debtors.count === 1,
      components: {
        income_taxable_primary: {
          lazy: () => import('../components/Smava/IncomeTaxable'),
          props: {
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            debtor: Debtor.PRIMARY,
            outlinedChoice: true
          }
        }
      }
    },
    income_further_single_taxable: {
      condition: ({ traversal: { data } }) => data.debtors.count === 1,
      components: {
        titie: {
          lazy: () => import('utils/headlines'),
          props: {
            text: 'Weitere Einkommen '
          }
        },
        income: {
          lazy: () => import('../components/Smava/IncomeTaxableFurther'),
          props: {
            debtor: Debtor.PRIMARY,
            ...{ ...ddfIntroductionStyleProps },
            outlinedChoice: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            showLabelAndInfo: false,
            showTypeAsSelect: true,
            isSquaredButtonStyle: true
          },
          skeleton: IncomeAllSkeleton
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () =>
            traversal.data.debtors.primary.realEstate.rentalProperty.type,
          method: () =>
            reactionResetRentalProperty(
              traversal.data.debtors.primary.realEstate.rentalProperty.type,
              traversal.handleMultiplePathFormFieldChanges
            )
        }
      ]
    },
    income: {
      condition: ({ traversal: { data } }) => data.debtors.count === 2,
      components: {
        employment_position: {
          lazy: () => import('../containers/EmploymentPosition'),
          props: {
            debtor: Debtor.PRIMARY,
            titleText: 'Beruf',
            showIntroduction: true,
            ...{ ...ddfIntroductionStyleProps },
            smallTextField: true,
            outerLabel: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true
          },
          skeleton: EmploymentPositionSkeleton
        },
        pensionFund: {
          lazy: () => import('../containers/PensionFund'),
          props: {
            debtor: Debtor.PRIMARY,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true
          }
        },
        minijob: {
          lazy: () => import('../components/PathYesNo'),
          props: {
            path: 'debtors.primary.hasMinijob',
            outlinedChoice: true,
            showTooltipOnFieldClick: true
          }
        },
        income: {
          lazy: () => import('../containers/IncomeAll'),
          props: {
            debtor: Debtor.PRIMARY,
            incomeTitle: 'Einkommen aus Hauptbeschäftigung',
            ...{ ...ddfIntroductionStyleProps },
            outlinedChoice: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            showLabelAndInfo: false,
            showTypeAsSelect: true,
            isSquaredButtonStyle: true
          },
          skeleton: IncomeAllSkeleton
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () =>
            traversal.data.debtors.primary.currentEmployment.position,
          method: () =>
            reactionResetHasMiniJob(
              Debtor.PRIMARY,
              traversal.data.debtors.primary.currentEmployment.position,
              traversal.handlePathFormFieldChange
            )
        }
      ]
    },
    income_secondary: {
      condition: ({
        traversal: {
          data: {
            debtors: { count }
          }
        }
      }) => count === 2,
      components: {
        employment_position: {
          lazy: () => import('../containers/EmploymentPosition'),
          props: {
            debtor: Debtor.SECONDARY,
            smallTextField: true,
            outerLabel: true,
            showTooltipOnFieldClick: true,
            titleText: 'Beruf',
            showIntroduction: true,
            withNoticeSmallerBorderRadius: true,
            useSimpleSelect: true,
            ...{ ...ddfIntroductionStyleProps }
          },
          skeleton: EmploymentPositionSkeleton
        },
        pensionFund: {
          lazy: () => import('../containers/PensionFund'),
          props: {
            debtor: Debtor.SECONDARY,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true
          }
        },
        minijob: {
          lazy: () => import('../components/PathYesNo'),
          props: {
            path: 'debtors.secondary.hasMinijob',
            outlinedChoice: true,
            showTooltipOnFieldClick: true
          }
        },
        income: {
          lazy: () => import('../containers/IncomeAll'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            incomeTitle: 'Einkommen aus Hauptbeschäftigung',
            outerLabel: true,
            smallTextField: true,
            outlinedChoice: true,
            showTooltipOnFieldClick: true,
            showLabelAndInfo: false,
            showTypeAsSelect: true,
            isSquaredButtonStyle: true
          },
          skeleton: IncomeAllSkeleton
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () =>
            traversal.data.debtors.secondary.currentEmployment.position,
          method: () =>
            reactionResetHasMiniJob(
              Debtor.SECONDARY,
              traversal.data.debtors.secondary.currentEmployment.position,
              traversal.handlePathFormFieldChange
            )
        }
      ]
    },
    costs_single: {
      condition: ({ traversal: { data } }) => data.debtors.count === 1,
      components: {
        rent: {
          lazy: () => import('../components/Smava/Costs'),
          props: {
            unit: Unit.EURO_MONTH,
            path: `debtors.primary.expenses.rent`,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            ...{ ...ddfIntroductionStyleProps }
          }
        }
      }
    },
    costs_single_further: {
      condition: ({ traversal: { data } }) => data.debtors.count === 1,
      components: {
        further_costs: {
          lazy: () => import('../components/Smava/CostsExtended'),
          props: {
            unit: Unit.EURO_MONTH,
            path: `debtors.primary.expenses.rent`,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            outlinedChoice: true,
            showExpenseCards: false,
            isSquaredButtonStyle: true,
            ...{ ...ddfIntroductionStyleProps }
          }
        }
      }
    },
    loadingLead: {
      components: {
        loadingLead: {
          lazy: () => import('../components/LoadingLead'),
          props: {
            variant: LoadingLeadVariantEnum.SUMMARY_AND_FAKE_OFFERS,
            isUsingSmavaCdnUrl: true
          }
        }
      },
      isFooterVisible: false
    },
    contact: {
      components: {
        title: {
          component: Typography,
          props: {
            variant: 'h4',
            children: 'Fast geschafft! Gleich erhalten Sie Ihre Kreditangebote.'
          }
        },
        body_text: {
          component: Typography,
          props: {
            component: 'p',
            children:
              'Dafür benötigen wir nur noch einige Angaben zu Ihrer Person. Und schon generieren wir Ihre persönliche Kreditrate.'
          }
        },
        personal_primary: {
          lazy: () => import('../containers/PersonalForm'),
          props: {
            debtor: Debtor.PRIMARY,
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            outlinedChoice: true,
            smallTextField: true,
            showTooltipOnFieldClick: true
          },
          skeleton: PersonalFormSkeleton
        },
        personal_secondary: {
          lazy: () => import('../containers/PersonalForm'),
          props: {
            debtor: Debtor.SECONDARY,
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            outlinedChoice: true,
            smallTextField: true,
            showTooltipOnFieldClick: true
          },
          skeleton: PersonalFormSkeleton
        },
        agb: {
          lazy: () => import('../components/Smava/AGB'),
          props: {
            disableDisclose: true
          }
        }
      }
    },
    personal: {
      components: {
        personal_primary: {
          lazy: () => import('../containers/Personal'),
          props: {
            debtor: Debtor.PRIMARY,
            hideIntroduction: true,
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            outlinedChoice: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true,
            isBirthdateImportanceNudgeEnabled: true
          },
          skeleton: PersonalSkeleton
        },
        personal_secondary: {
          lazy: () => import('../containers/Personal'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            hideIntroduction: true,
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            outlinedChoice: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true
          },
          skeleton: PersonalSkeleton
        }
      }
    },
    address: {
      components: {
        address_primary: {
          lazy: () => import('../containers/Address'),
          props: {
            debtor: Debtor.PRIMARY,
            showCountrySelectAndAutoComplete: true,
            isPaddingAdjustment: true,
            showIntroduction: false,
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true
          },
          skeleton: AddressSkeleton
        },
        sameAddress: {
          lazy: () => import('../components/PathYesNo'),
          props: {
            path: 'debtors.isSameAddress',
            outlinedChoice: true,
            showTooltipOnFieldClick: true
          }
        },
        address_secondary: {
          lazy: () => import('../containers/Address'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            showCountrySelectAndAutoComplete: true,
            isPaddingAdjustment: true,
            showIntroduction: false,
            ...{ ...ddfIntroductionStyleProps },
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true
          },
          skeleton: AddressSkeleton
        }
      }
    },
    employment: {
      condition: ({ traversal: { data } }) => showEmploymentPage(data),
      components: {
        employment_primary: {
          lazy: () => import('../containers/Smava/EmploymentFieldSet'),
          props: {
            debtor: Debtor.PRIMARY,
            ...{ ...ddfIntroductionStyleProps },
            isSectorLastField: true,
            isAddressHidden: true
          }
        },
        selfEmployment_primary: {
          lazy: () => import('../containers/SelfEmployment'),
          props: {
            debtor: Debtor.PRIMARY,
            isCountryAfterSelfEmploymentName: false,
            outerLabel: true,
            outlinedChoice: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            isPaddingAdjustment: true,
            useSimpleSelect: true
          }
        },
        employment_secondary: {
          lazy: () => import('../containers/Smava/EmploymentFieldSet'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            ...{ ...ddfIntroductionStyleProps },
            isSectorLastField: true,
            isAddressHidden: true
          }
        },
        selfEmployment_secondary: {
          lazy: () => import('../containers/SelfEmployment'),
          props: {
            debtor: Debtor.SECONDARY,
            isCountryAfterSelfEmploymentName: false,
            outerLabel: true,
            outlinedChoice: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            isPaddingAdjustment: true,
            useSimpleSelect: true
          }
        }
      }
    },
    activeLoans: {
      components: {
        activeLoans_primary: {
          lazy: () => import('../components/ActiveLoans'),
          props: {
            debtor: Debtor.PRIMARY,
            activeLoanTitleVariant: 'h4',
            isBannerIconVersionOutline: true,
            withSingleInfoLabel: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            outlinedChoice: true,
            useSimpleSelect: true,
            isSquaredButtonStyle: true,
            showTitle: false,
            consolidationMessageSmava: true,
            isOptionalWrapperDisplayed: true
          },
          skeleton: ActiveLoansSkeleton(Debtor.PRIMARY)
        },
        activeLoans_secondary: {
          lazy: () => import('../components/ActiveLoans'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            activeLoanTitleVariant: 'h4',
            isBannerIconVersionOutline: true,
            withSingleInfoLabel: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            outlinedChoice: true,
            useSimpleSelect: true,
            isSquaredButtonStyle: true,
            consolidationMessageSmava: true,
            isOptionalWrapperDisplayed: true
          },
          skeleton: ActiveLoansSkeleton(Debtor.SECONDARY)
        },
        adaptAmount: {
          lazy: () => import('../components/AdaptAmount'),
          props: {
            useSimpleSelect: true,
            outlinedChoice: true,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true
          }
        }
      }
    },
    vehicle: {
      condition: ({
        traversal: {
          data: { common }
        }
      }) => common.purpose === 'PRE_OWNED_CAR' || common.purpose === 'NEW_CAR',
      components: {
        kfz: {
          lazy: () => import('../containers/KfzForm'),
          skeleton: KfzFormSkeleton,
          props: {
            showVehiclePrice: false,
            outerLabel: true,
            smallTextField: true,
            showTooltipOnFieldClick: true,
            useSimpleSelect: true,
            showPowerUnitAsRadioButton: true
          }
        }
      }
    },
    rdi: {
      components: {
        ratenRdi: {
          lazy: () => import('../containers/Smava/Rdi'),
          props: {
            ...{ ...ddfIntroductionStyleProps },
            showAsPathIntroduction: true
          }
        }
      }
    },
    payoutAccount: {
      components: {
        payoutAccount: {
          lazy: () =>
            import('../containers/PayoutAccount/PayoutAccountWithDAC'),
          props: {
            listBackground: '#EDFAEB', // = theme.palette.primary[100]
            listWithIconsItems: [
              {
                text: 'Ihre Zugangsdaten sind niemals für uns einsehbar.',
                Icon: VisibilityOffIcon,
                isIconWithNoBackground: true
              },
              {
                text: 'Ihre Login Daten werden nicht gespeichert.',
                Icon: NoSimIcon,
                isIconWithNoBackground: true
              }
            ]
          }
        }
      }
    }
  }
} as LoadedConfig
