import Position from 'utils/formDependencies/statics/enums/Position'
import AccountType from 'utils/formDependencies/statics/enums/AccountType'
import { DebtorRelationship } from 'utils/formDependencies/statics/enums/DebtorRelationship'

export enum CreditChangeOption {
  nothing = 'DO_NOTHING',
  running = 'ADD_RUNNING_CREDIT',
  other = 'OTHER_AMOUNT'
}

export interface System {
  advertisementId: string
  clientUuid: string
  configurationId: string
  ipAddress: string
  refId?: string
  subId?: string
  externalId?: string
  affiliateId?: number
  leadId?: string
}

export interface PayoutAccount {
  accountNumber?: string
  bankCode?: string
  bic?: string
  country: string
  iban?: string
  type: AccountType
}

export interface SalaryAccount {
  iban?: string
}

export enum RDI_TYPE {
  UnemploymentDeathAndInability = 'UNEMPLOYMENT_DEATH_INABILITY',
  DeathAndInability = 'DEATH_INABILITY',
  death = 'DEATH',
  none = 'NONE'
}

export interface Rdi {
  hasAcceptedConsultation: boolean
  isSecondaryDebtorIncluded: boolean
  type: RDI_TYPE
}
export interface Vehicle {
  kilometerReading: number
  manufacturer: string
  manufacturingYear: number
  model: string
  power: number
  powerUnit: string
  price: number
  type: string
  initialPayment?: number
}

export interface Common {
  amount: number
  manuallyAdaptedAmount: number
  amountAdaption: CreditChangeOption
  term: number
  purpose: string
  hasAcceptedTerms: boolean
  loanPayout: string
  loanPayoutDate: string
  payoutAccount: PayoutAccount
  salaryAccount: SalaryAccount
  payoutAccountHolder: string
  productId: number | undefined
  rateDueOn: number
  rdi: Rdi
  vehicle: Vehicle
  comment: string
  dataProcessingConsent: boolean
}

export enum LoanType {
  Balloon = 'BALLOON_FINANCING',
  CreditLine = 'CREDIT_LINE',
  Overdraft = 'OVERDRAFT_LOAN',
  Demand = 'DEMAND_LOAN',
  CreditCard = 'CREDITCARD',
  Consumer = 'CONSUMER_CREDIT',
  Car = 'CAR_LOAN',
  ZeroPercent = 'ZERO_PERCENT_FINANCING',
  Business = 'BUSINESS_LOAN',
  Leasing = 'LEASING',
  Employer = 'EMPLOYERS_LOAN'
}

export interface BankAccount {
  accountNumber: string
  bankCode: string
  bic: string
  country: string
  iban: string
  type: AccountType
}

export interface ActiveLoansItem {
  amount: number
  bankAccount: BankAccount
  dispositionAmount: number
  endsOn: string
  isCommonLoanWithOtherDebtor: boolean
  isDebtConversionWanted: boolean
  monthlyRate: number
  remainingAmount: number
  startedOn: string
  type: LoanType
}

export interface Contact {
  email: string
  phoneNumber: string
}

export interface CurrentAddress {
  city: string
  country: string
  houseNumber: string
  street: string
  zipCode: string
}

export interface Address {
  city: string
  country: string
  houseNumber: string
  street: string
  zipCode: string
}

export interface Employer {
  address: Address
  name: string
}

export interface SelfEmployment {
  address: Address
  deductions: number
  isDoubleBookkeepingRequired: boolean
  legalForm: string
  name: string
  numberOfEmployees: string
  occupation: string
  profitBeforeLastYear: number
  profitLastYear: number
  revenueBeforeLastYear: number
  revenueLastYear: number
}

export interface CurrentEmployment {
  employer: Employer
  income?: number
  isExtended: boolean
  isGermanSalaryAccount: boolean
  isIncomeProvable: boolean
  isIncomeStable: boolean
  isInProbationPeriod: boolean
  isTemporary: boolean
  numberOfYears?: number
  occupation?: string
  position: Position
  sector?: string
  selfEmployment: SelfEmployment
  since?: string
  type?: string
  until?: string
  workingTime: string
}

export interface Expenses {
  additionalHousingCosts: number
  cards: string
  childSupport: number
  hasBike: boolean
  hasCar: boolean
  homePurchaseSavingsRate: number
  isHomePurchaseSavingsUsedForMortgageRepayment: boolean
  isLifeInsuranceUsedForMortgageRepayment: boolean
  lifeInsurance: number
  numberOfChildrenWithChildSupport: number
  mortgageRateUsedProperty: number
  mortgageRateRentalProperty: number
  privateHealthInsuranceRate: number
  rent: number
  spousalSupport: number
}

export enum LivingSituation {
  Property = 'PROPERTY',
  Renting = 'RENTING',
  RentFree = 'RENTFREE',
  Parents = 'PARENTS'
}

export interface Household {
  livingSituation: LivingSituation
  numberOfChildren: number
  numberOfResidents: number
}

export interface PensionsItem {
  income: number
  isTemporary: boolean
  type: string
  validUntil: string
}

export interface SecondaryEmploymentsItem {
  employer: Employer
  income: number
  isExtended: boolean
  isGermanSalaryAccount: boolean
  isIncomeProvable: boolean
  isIncomeStable: boolean
  isInProbationPeriod: boolean
  isTemporary: boolean
  numberOfYears: number
  occupation: string
  position: Position
  sector: string
  selfEmployment: SelfEmployment
  since: string
  type: Position
  until: string
  workingTime: string
}

export interface Income {
  childSupport: number
  hasFurtherIncome: boolean
  interest: number
  isMinijobIncomeStable: boolean
  isPrimaryIncomeStable: boolean
  minijob: number
  numberOfChildrenWithChildBenefits: number
  numberOfChildrenWithChildSupport: number
  numberOfSecondaryEmployments: number
  numberOfPensions: number
  pension: number
  pensions: PensionsItem[] | null
  primary: number
  rent: number
  secondaryEmployments: SecondaryEmploymentsItem[] | null
  spousalSupport: number
}

export interface PensionFund {
  address: Address
  name: string
  retiredSince: string
}

export interface Personal {
  birthday: string
  birthplace: string
  civilStatus: string
  firstname: string
  gender: string
  lastname: string
  livingInGermanySince: string
  nationality: string
  countryOfBirth: string
}

export interface PreviousAddress {
  city: string
  country: string
  houseNumber: string
  street: string
  zipCode: string
}

export interface PreviousEmployment {
  employer: Employer
  income: number
  isExtended: boolean
  isGermanSalaryAccount: boolean
  isIncomeProvable: boolean
  isIncomeStable: boolean
  isInProbationPeriod: boolean
  isTemporary: boolean
  numberOfYears: number
  occupation: string
  position: Position
  sector: string
  selfEmployment: SelfEmployment
  since: string
  type: string
  until: string
  workingTime: string
}

export interface SelfUsedProperty {
  size: number
  type: 'APPARTMENT' | 'SINGLE_FAMILY_HOUSE' | 'APPARTMENT_HOUSE' | 'OFFICE'
}

export interface RentalProperty {
  size: number
  type: 'NONE' | SelfUsedProperty['type']
}

export interface RealEstate {
  rentalProperty: RentalProperty
  selfUsedProperty: SelfUsedProperty
}

export interface ResidentAlien {
  isResidencePermitTemporary: boolean
  isWorkPermitTemporary: boolean
  residencePermitValidUntil: string
  workPermitValidUntil: string
}

export interface Insurance {
  address: Address
  name: string
  insuranceNumber: string
}

export interface LoansItem {
  deathSum: number
  dueDate: string
  insurance: Insurance
  isFondBased: boolean
  isRetirementBased: boolean
  isDeathCovered: boolean
  hasCapitalOption: boolean
  surrenderValueIncludingSurplus: number
  surrenderValueExcludingSurplus: number
}

export interface PolicyLoans {
  numberOfLoans: number
  hasMonthlyInterestPayment: boolean
  debtor: string
  loans: LoansItem[]
}

export interface Debtor {
  activeLoans: ActiveLoansItem[]
  contact: Contact
  currentAddress: CurrentAddress
  currentEmployment: CurrentEmployment
  expenses: Expenses
  hasMinijob: boolean
  hasPreviousEmployment: boolean
  household: Household
  income: Income
  numberOfActiveLoans: number
  pensionFund: PensionFund
  personal: Personal
  personId?: string // might be undefined if PDS times out
  previousAddress: PreviousAddress
  previousEmployment: PreviousEmployment
  realEstate: RealEstate
  residentAlien: ResidentAlien
  residentSince: string
  /**
   * temporary data for 'custom' inputs
   */
  temporary: {
    residentSince: {
      year: string
      month: string
    }
  }
  digitalAccountCheck: {
    transactionId: string
    createdAt: number
  }
}

export interface Debtors {
  count: number
  isCommonHousehold: boolean
  isSameAddress: boolean
  isSameContactInformation: boolean
  primary: Debtor
  secondary: Debtor
  relationship: DebtorRelationship
}

export enum TraversalErrorType {
  MISSING = 'missing',
  WRONG_FORMAT = 'wrong_format',
  WRONG_TYPE = 'wrong_type',
  INVALID = 'invalid',
  OUT_OF_RANGE = 'out_of_range',
  WRONG_LENGTH = 'wrong_length',
  CONTAINS_SPECIAL_CHARACTERS = 'contains_special_characters'
}

export interface TraversalError {
  id: TraversalErrorType
  actual?: string | number | null
  expected?: 'date' | 'integer' | 'string' | 'bool' | 'null'
  min?: string | number | null
  max?: string | number | null
}

export interface FailuresItem extends TraversalError {
  fields: string[]
}

export interface Patch {
  op: string
  path: string
  value: unknown
}

export interface Data {
  system: System
  common: Common
  debtors: Debtors
  policyLoans: PolicyLoans
}

export interface Result {
  id?: string
  accessToken?: string
  data?: Data
  validation?: {
    status: number
    result: Result
    errors: unknown[]
  }
  failures?: FailuresItem[]
  entryPoint?: string
}

export function isRootObject(
  obj: RootObject | { data: unknown }
): obj is RootObject {
  return (
    (obj as RootObject).result !== undefined &&
    (obj as RootObject).status !== undefined
  )
}

export interface RootObject<Extended = unknown> {
  status: number
  result: null | (Result & Extended)
  errors: unknown[]
}
