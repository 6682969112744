import AddressSkeleton from 'components/Address/AddressSkeleton'
import PersonalFormSkeleton from 'containers/PersonalForm/PersonalFormSkeleton'
import PersonalSkeleton from 'containers/PersonalSkeleton'
import HouseholdSkeleton from 'containers/HouseholdSkeleton'
import PropertySkeleton from 'containers/PropertySkeleton'
import IncomeAllSkeleton from 'containers/IncomeAllSkeleton'
import ExpensesSkeleton from 'containers/ExpensesSkeleton'
import ActiveLoansSkeleton from 'components/ActiveLoans/ActiveLoansSkeleton'
import KfzFormSkeleton from 'containers/KfzForm/KfzFormSkeleton'
import PayoutAccountSkeleton from 'containers/PayoutAccountSkeleton'
import Typography from '@mui/material/Typography'
import { Unit } from 'components/Input/Text'
import FormSealsSkeleton from 'components/SchufaLogo/Skeleton'
import { getFormSeals } from 'components/Footer/getSeals'
import { TwoDebtorsNudge } from 'components/Nudges/DebtorCount/TwoDebtorsNudge'
import { EmploymentPositionNudge } from 'components/Nudges/EmploymentPosition/EmploymentPositionNudge'
import EmploymentPositionSkeleton from 'containers/EmploymentPositionSkeleton'
import hasSecondDebtor from 'utils/formDependencies/conditions/hasSecondDebtor'
import isNotSpouse from 'utils/formDependencies/conditions/isNotSpouse'
import { SingleDebtorCountNudge } from 'components/Nudges/DebtorCount/SingleDebtorCountNudge'
import RootStore from '../stores/RootStore'
import {
  reactionClearMortgageRateUsedProperty,
  reactionClearSecondaryLivingSituation,
  reactionResetHasMiniJob,
  reactionUpdateHasPreviousEmployment
} from './utils/reactions'
import { CONFIG_DDF } from './utils/config'
import { LoadedConfig } from './config.interface'
import { LivingSituation } from '../interfaces/ApolloResponse.interface'
import {
  CheckIconsStates,
  Debtor
} from '../containers/PersonalForm/PersonalForm.interface'
import { showEmploymentPage } from './utils/conditions'
import { LoadingLeadVariantEnum } from '../components/LoadingLead'

const seals = getFormSeals()

export default {
  name: CONFIG_DDF,
  version: '2.0',
  pages: {
    base: {
      components: {
        headline: {
          component: Typography,
          props: {
            variant: 'h2',
            children: 'Ihre Kreditdaten',
            color: 'primary',
            textAlign: 'center'
          }
        },
        purpose: {
          lazy: () => import('../components/PathSelect'),
          props: {
            path: 'common.purpose'
          }
        },
        amount: {
          lazy: () => import('../components/PathAmountInput'),
          props: {
            path: 'common.amount',
            unit: Unit.EURO
          }
        },
        term: {
          lazy: () => import('../components/PathSelect'),
          props: {
            path: 'common.term'
          }
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () => traversal.data.common.purpose,
          method: () => traversal.removeErrorsForPath('common.amount')
        }
      ]
    },
    debtorCount: {
      components: {
        headline: {
          component: Typography,
          props: {
            variant: 'h3',
            children: 'Ihr persönlicher Kreditvergleich',
            color: 'primary',
            textAlign: 'center'
          }
        },
        seals: {
          lazy: () => import('../components/Footer/Seals'),
          props: {
            seals
          },
          skeleton: FormSealsSkeleton
        },
        debtor_count_nudge: {
          component: SingleDebtorCountNudge,
          condition: ({
            traversal: {
              data: {
                common: { purpose }
              }
            }
          }) => purpose === 'REFINANCING' || purpose === 'PRE_OWNED_CAR',
          props: {
            dependson: 'isSingleDebtor',
            carPurposeText:
              'Sehr gut! Für Fahrzeugfinanzierungen bieten Banken Sonderkonditionen an.',
            refinancingText:
              'Sehr gut! Banken schätzen es, wenn Sie laufende Kredite umschulden, anstatt einen neuen beantragen.',
            text: ''
          }
        },
        info_personal_data: {
          component: Typography,
          props: {
            component: 'p',
            variant: 'body1',
            children:
              'Persönliche Angebote gibt es nur nach einer Prüfung der Kreditwürdigkeit. Dafür benötigen wir Informationen zu Ihrer Person und finanziellen Situation.'
          }
        },
        info_overview: {
          component: Typography,
          props: {
            component: 'p',
            variant: 'body1',
            children:
              'Nach Eingabe dieser Daten erhalten Sie von uns einen Überblick über die besten für Sie verfügbaren Kreditangebote.'
          }
        },
        debtorChoice: {
          lazy: () => import('../components/DebtorChoice')
        },
        two_debtors_nudge: {
          component: TwoDebtorsNudge,
          props: {
            dependson: 'hasSecondDebtor',
            text: 'Sehr gut! Ein zweiter Kreditnehmer erhöht Ihre Chancen auf einen günstigen Kredit.',
            isCustomIconEnabled: true
          }
        },
        debtorRelationship: {
          lazy: () => import('../components/DebtorRelationship')
        }
      }
    },
    civilStatus: {
      components: {
        civilStatus_primary: {
          lazy: () => import('../components/CivilStatus'),
          props: {
            debtor: Debtor.PRIMARY
          }
        },
        civilStatus_secondary: {
          lazy: () => import('../components/CivilStatus'),
          props: {
            debtor: Debtor.SECONDARY
          }
        }
      },
      condition: isNotSpouse
    },
    household: {
      components: {
        commonHousehold: {
          lazy: () => import('../containers/CommonHousehold')
        },
        household_primary: {
          lazy: () => import('../containers/Household'),
          props: {
            debtor: Debtor.PRIMARY,
            adultResidentsNudgeText:
              'Mehr Erwachsene senken die Kosten pro Kopf. Das wiederum bedeutet mehr frei verfügbares Einkommen.',
            isCustomIconNudgeEnabled: true
          },
          skeleton: HouseholdSkeleton
        },
        household_secondary: {
          lazy: () => import('../containers/Household'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY
          },
          skeleton: HouseholdSkeleton
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () => traversal.data.debtors.isCommonHousehold,
          method: () =>
            reactionClearSecondaryLivingSituation(
              traversal.data.debtors.isCommonHousehold,
              traversal.data.debtors.secondary.household.livingSituation,
              traversal.handlePathFormFieldChange
            )
        },
        {
          field: () => traversal.data.debtors.primary.household.livingSituation,
          method: (livingSituation: LivingSituation) =>
            reactionClearMortgageRateUsedProperty(
              traversal,
              livingSituation,
              Debtor.PRIMARY
            )
        },
        {
          field: () =>
            traversal.data.debtors.secondary.household.livingSituation,
          method: (livingSituation: LivingSituation) =>
            reactionClearMortgageRateUsedProperty(
              traversal,
              livingSituation,
              Debtor.SECONDARY
            )
        }
      ]
    },
    realEstate: {
      components: {
        property_primary: {
          lazy: () => import('../containers/Property'),
          props: {
            debtor: Debtor.PRIMARY,
            isRentalPropertyNudgeDisabled: true
          },
          skeleton: PropertySkeleton
        },
        property_secondary: {
          lazy: () => import('../containers/Property'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY,
            isRentalPropertyNudgeDisabled: true
          },
          skeleton: PropertySkeleton
        }
      }
    },
    expenses: {
      components: {
        expenses_primary: {
          lazy: () => import('../containers/Expenses'),
          props: {
            debtor: Debtor.PRIMARY
          },
          skeleton: ExpensesSkeleton
        },
        expenses_secondary: {
          lazy: () => import('../containers/Expenses'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY
          },
          skeleton: ExpensesSkeleton
        }
      }
    },
    income: {
      components: {
        employment_position: {
          lazy: () => import('../containers/EmploymentPosition'),
          props: {
            debtor: Debtor.PRIMARY
          },
          skeleton: EmploymentPositionSkeleton
        },
        position_nudge: {
          component: EmploymentPositionNudge,
          props: {
            dependson: 'hasPositionNudgeRelevant',
            isAlternativeTextEnabled: true,
            isCustomIconEnabled: true
          }
        },
        pensionFund: {
          lazy: () => import('../containers/PensionFund'),
          props: {
            debtor: Debtor.PRIMARY
          }
        },
        minijob: {
          lazy: () => import('../components/PathYesNo'),
          props: {
            path: 'debtors.primary.hasMinijob'
          }
        },
        income: {
          lazy: () => import('../containers/IncomeAll'),
          props: {
            debtor: Debtor.PRIMARY
          },
          skeleton: IncomeAllSkeleton
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () =>
            traversal.data.debtors.primary.currentEmployment.position,
          method: () =>
            reactionResetHasMiniJob(
              Debtor.PRIMARY,
              traversal.data.debtors.primary.currentEmployment.position,
              traversal.handlePathFormFieldChange
            )
        }
      ]
    },
    income_secondary: {
      condition: ({
        traversal: {
          data: {
            debtors: { count }
          }
        }
      }) => count === 2,
      components: {
        employment_position: {
          lazy: () => import('../containers/EmploymentPosition'),
          props: {
            debtor: Debtor.SECONDARY
          },
          skeleton: EmploymentPositionSkeleton
        },
        pensionFund: {
          lazy: () => import('../containers/PensionFund'),
          props: {
            debtor: Debtor.SECONDARY
          }
        },
        minijob: {
          lazy: () => import('../components/PathYesNo'),
          props: {
            path: 'debtors.secondary.hasMinijob'
          }
        },
        income: {
          lazy: () => import('../containers/IncomeAll'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY
          },
          skeleton: IncomeAllSkeleton
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () =>
            traversal.data.debtors.secondary.currentEmployment.position,
          method: () =>
            reactionResetHasMiniJob(
              Debtor.SECONDARY,
              traversal.data.debtors.secondary.currentEmployment.position,
              traversal.handlePathFormFieldChange
            )
        }
      ]
    },
    loadingLead: {
      components: {
        loadingLead: {
          lazy: () => import('../components/LoadingLead'),
          props: {
            variant: LoadingLeadVariantEnum.HEADER_AND_FAKE_OFFERS,
            steps: [
              {
                title: 'Familienstand und Haushalt',
                state: CheckIconsStates.CHECKED
              },
              {
                title: 'Einnahmen & Ausgaben',
                state: CheckIconsStates.CHECKED
              },
              {
                title: 'Personalangaben für Kreditinstitute',
                description: 'und Angebote erhalten',
                state: CheckIconsStates.UNCHECKED
              }
            ]
          }
        }
      },
      isFooterVisible: false
    },
    contact: {
      components: {
        title: {
          component: Typography,
          props: {
            variant: 'h3',
            children: 'Erhalten Sie Ihre Kreditangebote',
            color: 'primary',
            textAlign: 'center'
          }
        },
        info_nearly_done: {
          component: Typography,
          props: {
            component: 'p',
            children:
              'Fast geschafft. Um die für Sie besten Kreditangebote erzeugen zu können, benötigen wir nur noch einige Angaben zu Ihrer Person.'
          }
        },
        personal_primary: {
          lazy: () => import('../containers/PersonalForm'),
          props: {
            debtor: Debtor.PRIMARY,
            additonalPhoneNumberToolTip: 'Alternativ Festnetznummer'
          },
          skeleton: PersonalFormSkeleton
        },
        personal_secondary: {
          lazy: () => import('../containers/PersonalForm'),
          props: {
            debtor: Debtor.SECONDARY,
            additonalPhoneNumberToolTip: 'Alternativ Festnetznummer'
          },
          skeleton: PersonalFormSkeleton
        },
        agb: {
          lazy: () => import('../components/AGB'),
          props: {
            isHeadlineHidden: true
          }
        }
      }
    },
    personal: {
      components: {
        seals: {
          lazy: () => import('../components/Footer/Seals'),
          props: {
            seals
          },
          skeleton: FormSealsSkeleton
        },
        personal_primary: {
          lazy: () => import('../containers/Personal'),
          props: {
            debtor: Debtor.PRIMARY,
            birthDateNudgeText:
              'Kreditnehmer in Ihrem Alter haben besonders gute Chancen einen Kredit zu erhalten.',
            isCustomIconNudgeEnabled: true
          },
          skeleton: PersonalSkeleton
        },
        personal_secondary: {
          lazy: () => import('../containers/Personal'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY
          },
          skeleton: PersonalSkeleton
        }
      }
    },
    address: {
      components: {
        address_primary: {
          lazy: () => import('../containers/Address'),
          props: {
            debtor: Debtor.PRIMARY
          },
          skeleton: AddressSkeleton
        },
        sameAddress: {
          lazy: () => import('../components/PathYesNo'),
          props: {
            path: 'debtors.isSameAddress'
          }
        },
        address_secondary: {
          lazy: () => import('../containers/Address'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY
          },
          skeleton: AddressSkeleton
        }
      }
    },
    employment: {
      condition: ({ traversal: { data } }) => showEmploymentPage(data),
      components: {
        employment_primary: {
          lazy: () => import('../containers/EmploymentFieldSet'),
          props: {
            debtor: Debtor.PRIMARY
          }
        },
        selfEmployment_primary: {
          lazy: () => import('../containers/SelfEmployment'),
          props: {
            debtor: Debtor.PRIMARY
          }
        },
        employment_secondary: {
          lazy: () => import('../containers/EmploymentFieldSet'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY
          }
        },
        selfEmployment_secondary: {
          lazy: () => import('../containers/SelfEmployment'),
          props: {
            debtor: Debtor.SECONDARY
          }
        }
      },
      reactions: ({ traversal }) => [
        {
          field: () =>
            traversal.data.debtors.primary.currentEmployment.since +
            traversal.data.debtors.primary.currentEmployment.position,
          method: () =>
            reactionUpdateHasPreviousEmployment(traversal, Debtor.PRIMARY)
        },
        {
          field: () =>
            traversal.data.debtors.secondary.currentEmployment.since +
            traversal.data.debtors.secondary.currentEmployment.position,
          method: () =>
            reactionUpdateHasPreviousEmployment(traversal, Debtor.SECONDARY)
        }
      ]
    },
    activeLoans: {
      components: {
        activeLoans_primary: {
          lazy: () => import('../components/ActiveLoans'),
          props: {
            debtor: Debtor.PRIMARY
          },
          skeleton: ActiveLoansSkeleton(Debtor.PRIMARY)
        },
        activeLoans_secondary: {
          lazy: () => import('../components/ActiveLoans'),
          condition: ({ traversal: { data } }: RootStore) =>
            hasSecondDebtor(data),
          props: {
            debtor: Debtor.SECONDARY
          },
          skeleton: ActiveLoansSkeleton(Debtor.SECONDARY)
        },
        adaptAmount: {
          lazy: () => import('../components/AdaptAmount')
        }
      }
    },
    vehicle: {
      condition: ({
        traversal: {
          data: { common }
        }
      }) => common.purpose === 'PRE_OWNED_CAR' || common.purpose === 'NEW_CAR',
      components: {
        kfz: {
          lazy: () => import('../containers/KfzForm'),
          skeleton: KfzFormSkeleton
        }
      }
    },
    rdi: {
      components: {
        ratenRdi: {
          lazy: () => import('../containers/Rdi')
        }
      }
    },
    payoutAccount: {
      components: {
        payoutAccount: {
          lazy: () => import('../containers/PayoutAccount'),
          skeleton: PayoutAccountSkeleton
        },
        dac: {
          lazy: () => import('../containers/DacContainer')
        },
        secondDebtorNotice: {
          lazy: () => import('../components/AddSecondDebtorNotice')
        },
        scufText: {
          lazy: () => import('../containers/ScufText')
        }
      }
    }
  }
} as LoadedConfig
